import React, {Component} from 'react';
import './App.css';
import {
  Navbar,
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Card,
  ListGroup,
  Spinner
} from 'react-bootstrap';
import './css/all.min.css';
const axios = require('axios');
const fetch = require('node-fetch');


export default class App extends Component {
  state = {
    username:'',
    colTwoTitle: '',
    error: null,
    users: null,
    tweets: null,
    dataReturned:false,
    selectedUser:null,
    spinning: false,
    webhook:'',
    error:null
  }

  async userSearch() {
    axios('https://pbz5u7ghwk.execute-api.us-east-1.amazonaws.com/v1/users/' + this.state.username)
      .then(response => {this.setState({users:response.data})})
  }

  async getTweets(screen_name) {
    let url = 'https://pbz5u7ghwk.execute-api.us-east-1.amazonaws.com/v1/tweets/' + screen_name
    axios(url)
      .then((response) => {
        this.setState({tweets:response.data, spinning:false})
        if(this.state.webhook) {
          this.postTweets()
        } else {
          //download the tweets in json file
          this.downloadTweets(screen_name)
        }
      })
      .catch((error) =>  { console.log(error) })
  }

  async downloadTweets(screen_name) {
    const {tweets} = this.state;
    const fileName = screen_name + 'Tweets';
    const json = JSON.stringify(tweets);
    const blob = new Blob([json],{type:'application/json'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  postTweets() {
    fetch(this.state.webhook,
      {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.tweets)
      }
    )
  }

  render() {
    let returnedUsers = null
    if(this.state.users) {
      returnedUsers = this.state.users.map((user) => {
        return (
          <Card key={user.id} style={{ width: '25rem', border:'none' }}>

            <Card.Body>
              <Card.Title>{user.name}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                @{user.screen_name}
              </Card.Subtitle>
              <Card.Text style={{ color: '#555' }}>
                {user.description}
              </Card.Text>
              <ListGroup variant="flush" style={{ color: '#555' }}>
                {user.location &&
                  <ListGroup.Item><span className="mb-2 text-muted">
                    Location: </span>{user.location}
                  </ListGroup.Item>
                }
                <ListGroup.Item><span className="mb-2 text-muted">
                  Tweets: </span>{user.statuses_count}
                </ListGroup.Item>
                <ListGroup.Item><span className="mb-2 text-muted">
                  Likes: </span>{user.favourites_count}
                </ListGroup.Item>
              </ListGroup>
              {user.status.text &&
                <Card bg="primary" text="white">
                  <Card.Body>
                    <Card.Title>Most Recent Tweet</Card.Title>
                    <Card.Text>
                      {user.status.text}
                    </Card.Text>
                  </Card.Body>
                </Card>
              }
              <br/>
              {this.state.spinning ?
                <div className="justify-content-center">
                  <Spinner animation="grow" variant="primary" />
                </div>
                  :
                <Button variant="primary" block
                onClick={()=> {
                  this.setState({spinning:true});
                  this.getTweets(user.screen_name)
                }}
                >
                  Get Tweets
                </Button>
              }

            </Card.Body>
          </Card>
        )
      })
    }

    return (
      <div className="app">
          <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />

        <Navbar style={{height:'100px', marginLeft:'50px'}} >
          <Navbar.Brand href="#home">
            <img
              alt=""
              src={require('./chirpette.svg')}
              height="50"
              className="d-inline-block align-top"
            />

          </Navbar.Brand>
          <h4>CHIRPETTE</h4>
        </Navbar>

        <Container>
          <Row style={{width:"100%"}}>
            <Col md={6}>
              <h1 className="text-left">Search Params</h1>
              <p>Enter the username of the twitter user to retrieve their tweets</p>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="atsymbol">@</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  value={this.state.username}
                  onChange={(value) => this.setState({username:value.target.value})}
                  placeholder="username"
                  aria-label="username"
                  aria-describedby="atsymbol"
                  size="lg"
                />
              </InputGroup>
              <br/><br/>
              <h2>Webhook</h2>
              <p>Chirpette supports webhooks! Just enter the url where the tweets should be sent. <i style={{opacity:.5}}>If you don't want to use webhooks, a json file will automatically download after confirming user</i></p>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="post">post</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="https://yoururl.com/tweets"
                  onChange={(value) => this.setState({webhook:value.target.value})}
                  aria-describedby="atsymbol"
                  size="lg"
                />
              </InputGroup>
              <Button variant="dark" size="lg" block
                onClick={() => {this.userSearch()}}>
                Find User
              </Button>
            </Col>
            {this.state.users &&
              <Col md={6} style={{display:'flex', justifyContent:'center',alignItems: 'center', overflowY:'scroll' }}>
              {returnedUsers}
              </Col>
            }
          </Row>
        </Container>
      </div>
    );
  }
}
